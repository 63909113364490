<template>
  <v-card class="card-shadows border" color="white">
    <!-- Success/Error Message Section -->
    <v-card-title v-if="successMessage" class="success-sec-btn mb-2">
      {{ successMessage }}
    </v-card-title>
    <v-card-title v-if="errorMessage" class="error-sec-btn mb-2">
      {{ errorMessage }}
    </v-card-title>

    <!-- Search and Create Client Section -->
    <v-row align="end" class="pa-8 " style="gap: 30px">
      <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details
                    class="search-field" @input="onSearchInput" />
      <!-- <v-btn color="primary" @click.prevent="ClientList(search)">
        Search
      </v-btn> -->
      <v-btn color="primary" @click.prevent="handleCreateClient">
        Create
      </v-btn>
    </v-row>

    <!-- Data Table Section -->
    <v-data-table :headers="headers" :items="sortedTableData" hide-default-footer :loading="loading"
                  :loading-text="message" :items-per-page="currentRows">
      <template v-slot:item.action="{ item }">
        <v-row justify="end" class="d-flex">
          <v-btn style="margin-left: 15px;" small color="primary" @click.prevent="handleAddWebhook(item)">
            Add Webhook
          </v-btn>
          <v-btn style="margin-left: 15px;" small color="secondary" @click.prevent="viewWebhooks(item)">
            View Webhooks
          </v-btn>
        </v-row>
      </template>
    </v-data-table>

    <!-- Pagination Section -->
    <v-row align="center" justify="end">
      <v-col cols="auto">
        <v-select style="width: 60px;" :items="rowsPerPage" @change="handleRowsPerPageChange" v-model="currentRows" />
      </v-col>
      <v-col cols="auto" style="min-width: 400px;">
        <v-pagination @input="pageChange" color="black" v-model="currentPage" :length="noOfPages" :total-visible="7" />
      </v-col>
    </v-row>

    <!-- Create Client Dialog -->
    <v-dialog v-model="dialog" persistent max-width="650px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Create Client</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field label="Name" v-model="uName" required />
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field label="Email" v-model="uEmail" required />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="error" text @click="dialog = false">
            Close
          </v-btn>
          <v-btn color="success" @click.prevent="createClient()">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Webhook Dialog for URL and Headers -->
    <v-dialog v-model="webhookDialog" persistent max-width="650px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Add Webhook</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field label="Webhook URL" v-model="webhookUrl" required />
              </v-col>
            </v-row>
            <v-row v-for="(header, index) in headersList" :key="index">
              <v-col cols="5">
                <v-text-field v-model="header.key" label="Header Key" />
              </v-col>
              <v-col cols="5">
                <v-text-field v-model="header.value" label="Header Value" />
              </v-col>
              <v-col cols="2">
                <v-btn color="red" @click="removeHeader(index)" v-if="headersList.length > 1">
                  Remove
                </v-btn>
              </v-col>
            </v-row>
            <v-btn color="primary" @click="addHeader">
              Add More Headers
            </v-btn>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="error" text @click="webhookDialog = false">
            Close
          </v-btn>
          <v-btn color="success" @click.prevent="saveWebhook">
            Save Webhook
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Webhooks List Dialog -->
    <v-dialog v-model="webhooksListDialog" max-width="800px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Client Webhooks</span>
        </v-card-title>
        <v-card-text>
          <v-data-table :headers="webhookHeaders" :items="webhooksList" item-key="id">
            <template v-slot:item.url="{ item }">
              <span>{{ item.url }}</span>
            </template>
            <template v-slot:item.headers="{ item }">
              <v-list>
                <v-list-item v-for="(value, key) in item.headers" :key="key">
                  <v-list-item-content>
                    <v-list-item-title><strong>{{ key }}:</strong> {{ value }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-btn color="error" text @click="webhooksListDialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Snackbar for feedback -->
    <v-snackbar v-model="snackbar" :timeout="timeout" color="primary" outlined>
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>


<script>

export default {
  data() {
    return {
      currentPage: 1,
      totalData: 0,
      rowsPerPage: [10, 15, 30, 50],
      currentRows: 10,
      successMessage: '',
      errorMessage: '',
      timeoutDuration: 3000,
      tableData: [],
      message: 'Loading... Please wait',
      loading: true,
      search: '',
      headers: [
        { text: 'Name', align: 'start', value: 'name', sortable: true },
        { text: 'Email', value: 'email', sortable: true },
        { text: 'Action', value: 'action', sortable: false, align: 'center' },
      ],
      dialog: false,
      uName: '',
      uEmail: '',
      snackbar: false,
      snackbarText: '',
      timeout: 3000,
      abortController: new AbortController(),

      // Webhook Dialog Data
      selectedClient: null,
      webhookDialog: false,
      webhookUrl: '',
      headersList: [{ key: '', value: '' }],

      // Client Webhooks List
      webhooksListDialog: false,
      webhooksList: [],
      webhookHeaders: [
        { text: 'Webhook URL', value: 'url' },
        { text: 'Headers', value: 'headers' }
      ],
    };
  },
  computed: {
    noOfPages() {
      if (!this.search) {
        return Math.ceil(this.totalData / this.currentRows);
      }
      return Math.ceil(this.tableData.length / this.currentRows);
    },
    sortedTableData() {
      if (!this.search) {
        return this.tableData
      }
      return this.tableData.filter((item) => {
        const searchTerm = this.search.toLowerCase();
        return (
          item.name.toLowerCase().includes(searchTerm) ||
          item.email.toLowerCase().includes(searchTerm)
        );
      })
    }
  },
  methods: {
    showMessage(message, type = 'success') {
      // Set the success or error message
      if (type === 'success') {
        this.successMessage = message;
        this.errorMessage = '';  // Clear error message if any
      } else {
        this.errorMessage = message;
        this.successMessage = '';  // Clear success message if any
      }

      // Clear any existing timeout
      if (this.messageTimeout) {
        clearTimeout(this.messageTimeout);
      }

      // Hide message after timeout
      this.messageTimeout = setTimeout(() => {
        this.successMessage = '';
        this.errorMessage = '';
      }, this.timeoutDuration); // Set timeout for 3 seconds (3000ms)
    },

    // Centralized fetch function
    async fetchData(url, options) {
      try {
        const response = await fetch(url, options);
        if (!response.ok) {
          this.errorBody = await response.json();
          this.showMessage(this.errorBody.error || 'Unknown error occurred', 'error');
          throw new Error(this.errorMessage);
        }
        return await response.json();
      } catch (error) {
        console.error('Error fetching data:', error);
        this.loading = false;
        this.message = 'Something went wrong';
        throw error;
      }
    },

    async ClientList() {
      const baseurl = process.env.VUE_APP_API_BASE_URL;
      const url = `${baseurl}admin/clients?page=${this.currentPage}&per_page=${this.currentRows}`;
      const headers = new Headers({
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      });

      const options = {
        method: 'GET',
        headers,
        signal: this.abortController.signal,
      };

      const data = await this.fetchData(url, options);

      this.tableData = data.data.map(item => ({
        id: item.id,
        email: item.email ?? '--',
        name: item.name ?? '--',
        action: 'Actions',
      }));

      this.totalData = data?.total_entries
      this.currentPage = data?.current_page || 1; // Reset to the first page
      this.loading = false;
    },

    pageChange() {
      this.ClientList();
    },

    handleRowsPerPageChange() {
      this.currentPage = 1
      this.ClientList();
    },

    handleCreateClient() {
      this.dialog = true;
    },

    async createClient() {
      const baseurl = process.env.VUE_APP_API_BASE_URL;
      const url = `${baseurl}admin/clients`;
      const headers = new Headers({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
      });

      const options = {
        method: 'POST',
        headers,
        body: JSON.stringify({ name: this.uName, email: this.uEmail }),
      };

      try {
        await this.fetchData(url, options);
        this.dialog = false;
        this.uName = '';
        this.uEmail = '';
        this.ClientList();
        this.showMessage('Client created successfully', 'success');
      } catch (error) {
        this.showMessage('Failed to create client', 'error');
      }
    },

    handleAddWebhook(item) {
      this.selectedClient = item;
      this.webhookUrl = '';
      this.headersList = [{ key: '', value: '' }];
      this.webhookDialog = true;
    },

    addHeader() {
      this.headersList.push({ key: '', value: '' });
    },

    removeHeader(index) {
      if (this.headersList.length > 1) {
        this.headersList.splice(index, 1);
      }
    },

    async saveWebhook() {
      // Validate webhook URL and headers before sending
      if (!this.webhookUrl || this.headersList.some(header => !header.key || !header.value)) {
        this.showMessage('Please provide valid URL and header values', 'error');
        return;
      }

      const headersObj = this.headersList.reduce((obj, { key, value }) => {
        obj[key] = value;
        return obj;
      }, {});

      const webhookData = {
        url: this.webhookUrl,
        client_id: this.selectedClient.id,
        headers: headersObj,
      };

      const baseurl = process.env.VUE_APP_API_BASE_URL;
      const url = `${baseurl}admin/clients/webhook`;
      const headers = new Headers({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
      });

      const options = {
        method: 'POST',
        headers,
        body: JSON.stringify(webhookData),
      };

      try {
        await this.fetchData(url, options);
        this.webhookDialog = false;
        this.selectedClient = null;
        this.snackbarText = 'Webhook saved successfully';
        this.snackbar = true;
      } catch (error) {
        this.showMessage('Failed to save webhook', 'error');
      }
    },

    async viewWebhooks(client) {
      // Fetch webhooks associated with the client
      const baseurl = process.env.VUE_APP_API_BASE_URL;
      const url = `${baseurl}admin/clients/${client.id}/webhooks`;
      const headers = new Headers({
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
      });

      try {
        const data = await this.fetchData(url, { method: 'GET', headers });
        this.webhooksList = data ?? [];
        this.webhooksListDialog = true;
      } catch (error) {
        console.error('Error fetching webhooks:', error);
      }
    },
  },

  mounted() {
    this.ClientList();
  },
};
</script>